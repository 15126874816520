body,
button,
input {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

.copyable {
  cursor: copy;
}

body {
  margin: 0;
}

html,
body,
#app {
  height: 100%;
}

.app {
  display: grid;
  grid-template-rows: min-content auto;
  height: 100%;
}

.controls {
  display: grid;
  grid-template-columns: repeat(4, max-content) 1fr repeat(2, max-content);
  grid-gap: 10px;
  padding: 14px;
  border-bottom: 1px solid #ddd;
}

.controls > *:nth-child(5) {
  grid-column-start: 6;
}

.signedOut {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
}

.signInForm {
  display: grid;
  grid-gap: 10px;
}

.signInForm .divider {
  text-align: center;
  font-size: small;
  font-variant: small-caps;
  color: grey;
}

.graphiql-container {
  height: 100vh;
  width: 100vw;
}

pre {
  counter-reset: line-numbering;
  background: #2c3e50;
  padding: 1rem;
  color: #ecf0f1;
  line-height: 100%;
}

pre .line::before {
  content: counter(line-numbering);
  counter-increment: line-numbering;
  padding-right: 1em;
  /* space after numbers */
  padding-left: 8px;
  width: 1.5em;
  text-align: right;
  opacity: 0.5;
  color: white;
}
