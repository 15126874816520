body, button, input {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

.copyable {
  cursor: copy;
}

body {
  margin: 0;
}

html, body, #app {
  height: 100%;
}

.app {
  grid-template-rows: min-content auto;
  height: 100%;
  display: grid;
}

.controls {
  grid-gap: 10px;
  border-bottom: 1px solid #ddd;
  grid-template-columns: repeat(4, max-content) 1fr repeat(2, max-content);
  padding: 14px;
  display: grid;
}

.controls > :nth-child(5) {
  grid-column-start: 6;
}

.signedOut {
  background-color: #f0f0f0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.signInForm {
  grid-gap: 10px;
  display: grid;
}

.signInForm .divider {
  text-align: center;
  font-variant: small-caps;
  color: gray;
  font-size: small;
}

.graphiql-container {
  width: 100vw;
  height: 100vh;
}

pre {
  counter-reset: line-numbering;
  color: #ecf0f1;
  background: #2c3e50;
  padding: 1rem;
  line-height: 100%;
}

pre .line:before {
  content: counter(line-numbering);
  counter-increment: line-numbering;
  text-align: right;
  opacity: .5;
  color: #fff;
  width: 1.5em;
  padding-left: 8px;
  padding-right: 1em;
}
/*# sourceMappingURL=index.ce42e25f.css.map */
